import React from 'react';
import AppHeading from '../../components/heading/app.rest.heading';
import './app.aboutus.scss';

function AppAboutus(props) {
    return (
      <React.Fragment>
        <AppHeading heading="ABOUT US"/>
        <section id="res-aboutus">
          <article>
            <p>Some text goes here</p>
          </article>
        </section>
      </React.Fragment>
    )
}

export default AppAboutus;
