import React, { Component, lazy, Suspense } from 'react';
import axios from 'axios';
import AppHeader from './components/header/app.header';
import AppFooter from './components/footer/app.footer';
import AppAboutus from './pages/aboutus/app.aboutus';
import AppServices from './pages/services/app.services';
import AppCareer from './pages/career/app.career';
import AppContactus from './pages/contactus/app.contactus';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './App.scss';
// import appConfig from './config/config';
import Data from './constant/data.json'
const AppLanding = lazy(() => import ('./home/app.landing'));

class App extends Component {
  constructor(props) {
    super()
    this.state = {
        data: [],
        fname: '',
        lname: '',
        email: '',
        message: '',
    }
}
componentDidMount() {
  window.addEventListener('scroll', this.handleScroll, true);
  // this.fetchUsers();
}
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  nav = React.createRef();
  handleScroll = () => {
    const lastScrollY = window.scrollY;
    lastScrollY>100 ? this.nav.current.className = "sticky" : this.nav.current.className = "";
    const journey = document.getElementById("journey");
    if (journey) {
      let windowHeight = window.innerHeight;
      let inView = lastScrollY-journey.offsetTop+windowHeight-150;
      inView>0 ? journey.className = "inView": journey.className = '';
    }
  };

//   fetchUsers() {
//     fetch(appConfig.API_URL+"landingPages")
//         .then(stream => stream.json())
//         .then(data =>
//             this.setState({
//                 data: data,
//             })
//         )
//         .catch(error => this.setState({ error, isLoading: false},console.log("json-server --watch file-name")));
// }

handleFormSubmit = e => {
  debugger;
  const API_PATH = 'http://vvrss.com/contact.php';
  e.preventDefault();
  axios({
    method: 'post',
    url: `${API_PATH}`,
    headers: { 'content-type': 'application/json' },
    data: this.state
  })
    .then(result => {
      this.setState({
        mailSent: result.data.sent
      })
    })
    .catch(error => this.setState({ error: error.message }));
};

  render() {
    const { data } = this.state;
    return (
      <Router>
        <Suspense fallback={<div className="loading">Loading...</div>}>
          <AppHeader ref={this.nav}/>
          <div className="ui-content">
          <form action="#" className="hide">
            <h1>Hi T
              {this.state.mailSent &&
                <div>Thank you for contcting us.</div>
              }
            </h1>
            <label>First Name</label>
            <input type="text" id="fname" name="firstname" placeholder="Your name.."
              value={this.state.fname}
              onChange={e => this.setState({ fname: e.target.value })}
            />
            <label>Last Name</label>
            <input type=" text" id="lname" name="lastname" placeholder="Your last name.."
              value={this.state.lname}
              onChange={e => this.setState({ lname: e.target.value })}
            />


            <label>Email</label>
            <input type="email" id="email" name="email" placeholder="Your email"
              value={this.state.email}
              onChange={e => this.setState({ email: e.target.value })}
            />


            <label>Message</label>
            <textarea id="message" name="message" placeholder="Write something.."
              onChange={e => this.setState({ message: e.target.value })}
              value={this.state.message}
            ></textarea>
            <input type="submit" onClick={e => this.handleFormSubmit(e)} value="Submit" />
          </form>
            <Switch>
              <Route path="/about">
                <AppAboutus AboutUs={Data.AboutUs}/>
              </Route>
              <Route path="/services">
                <AppServices ServicesList={Data.ServicesList}/>
              </Route>
              <Route path="/blogs">
                <AppCareer/>
              </Route>
              <Route path="/contact">
                <AppContactus ContactUs={Data.ContactUs}/>
              </Route>
              <Route path="/">
                <AppLanding data={Data}/>
              </Route>
            </Switch>
          </div>
          <AppFooter/>
        </Suspense>
      </Router>
    );
  }
}

export default App;