import React from 'react';
import './app.footer.scss'

function AppFooter() {
    
    return (
        <footer>
            <div className="container">
                <div className="row foot">
                    <div className="col">
                        <h4>ABOUT US</h4>
                        <div className="cont">
                            <p>Summary of the rights of passengers travling by sea and inland waterway</p>
                            <p>Term and condition of the provision of tourism services</p>
                        </div>
                        <div className="social">
                            <a className="icon-facebook" target="_blank" href="https://www.facebook.com/abindia.relocation.5/" data="facebook"></a>
                            <a className="icon-youtube" target="_blank" data="youtube"></a>
                            <a className="icon-instagram" target="_blank" href="https://www.instagram.com/vvrss/" data="instagram"></a>
                            <a className="icon-twitter" target="_blank" href="https://twitter.com/b_relocation" data="twitter"></a>
                        </div>
                    </div>
                    <div className="col">
                        <h4>RECENT BLOG</h4>
                        <img src="blog.png" alt="blog"/>
                    </div>
                    <div className="col">
                        <h4>Quick Action</h4>
                        <div className="cont">
                            <p><a>About us</a></p>
                            <p><a>Services</a></p>
                            <p><a>Career</a></p>
                        </div>
                    </div>
                    <div className="col">
                        <h4>HAVE A QUESTIONS?</h4>
                        <img src="adr.png" alt="blog"/>
                    </div>
                </div>
                <hr/>
                <div className="row copyright">
                    <span>Copyright © 2023 vvrss. All Right Reserved.</span>
                </div>
            </div>
        </footer>
    )
}

export default AppFooter;
