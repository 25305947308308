import React,{useState} from 'react';
import { NavLink } from 'react-router-dom';
import './app.header.scss'
const AppHeader = React.forwardRef((props, ref) => {
    const [panelToogle, setPanel] = useState(false);
    function toggleButton () {
        if(!panelToogle) setPanel(true);
        else setPanel(false);
    }
    function toggleButtonOff () {
        setPanel(false);
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }
    // const { location } = props;
    return(
        <header id="app-header" ref={ref}>
            <nav className="navbar navbar-default">
                <div className={"container " + (panelToogle ? 'openNav' : '')}>
                    <div className="navbar-brand" href="//www.airtel.in" >
                        <span className="logo">
                            <img src="vvrss_logo.png"/>
                        </span>
                    </div>
                    <a className="mobile-nav icon-menu" onClick={toggleButton}></a>
                    <ul id="main-menu" className="main-menu">
                        <li><NavLink exact to="/">Home</NavLink></li>
                        <li><NavLink to="/about">About us</NavLink></li>
                        <li><NavLink to="/services">Services</NavLink></li>
                        <li><NavLink to="/blogs">Blogs</NavLink></li>
                        <li><NavLink to="/contact">Contact us</NavLink></li>
                    </ul>
                    <ul className="right-menu">
                        <li className="phone"><span className="icon-phone">1800 000 XXXX</span></li>
                    </ul>
                    {/* <p>{location.location.pathname}</p> */}
                    
                </div>
            </nav>
        </header>
    )
})

export default AppHeader;

