import React from 'react'
import './app.heading.scss';
function AppHeading(props) {
    return (
        <section className="rest-banner">
            <article>
                <h2 className='rest heading'>
                    {props.heading}
                    {props.subHeading? <span>{props.subHeading}</span> :null}
                </h2>
            </article>
        </section>
    )
}

export default AppHeading;
