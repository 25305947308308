import React from 'react';
import AppHeading from '../../components/heading/app.rest.heading';
import CareerForm from './app.Form';
import './app.career.scss';

function AppCareer(props) {
    return (
      <React.Fragment>
        <AppHeading heading="Blogs"/>
        <section id="rest-career">
          <article>
            <div className="row career-form">
              <div className="col">
                  {/* <CareerForm/> */}
              </div>
              <div className="col">
                
              </div>
            </div>
          </article>
        </section>
      </React.Fragment>
    )
}

export default AppCareer;
