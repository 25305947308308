import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();







// import React, { useState, useEffect } from "react";
// import ReactDOM from "react-dom";


// function GithubCommit() {
//   const [page, setPage] = useState(1);
//   const [commitHistory, setCommitHistory] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   const loadMoreCommit = () => {
//     setPage(page + 1);
//   };

//   useEffect(() => {
//     fetch(
//       `https://api.github.com/search/commits?q=repo:facebook/react+css&page=${page}`,
//       {
//         method: "GET",
//         headers: new Headers({
//           Accept: "application/vnd.github.cloak-preview"
//         })
//       }
//     )
//       .then(res => res.json())
//       .then(response => {
//         setCommitHistory(response.items);
//         setIsLoading(false);
//       })
//       .catch(error => console.log(error));
//   }, [page]);

//   return (
//     <div>
//       <h1> API calls with React Hooks </h1>
//       {isLoading && <p>Wait I'm Loading comments for you</p>}

//       {commitHistory.length !== 0 && (
//         <button onClick={loadMoreCommit}>Load More Commits</button>
//       )}

//       {commitHistory.map((c, index) => (
//         <div key={index}>
//           {c.commit && (
//             <>
//               <div>
//                 <h2 style={{ textDecoration: "Underline" }}>
//                   {c.commit.committer.name}
//                 </h2>
//                 <p>{c.commit.message}</p>
//               </div>
//               <hr />
//             </>
//           )}
//         </div>
//       ))}
//     </div>
//   );
// }

// const rootElement = document.getElementById("root");
// ReactDOM.render(<GithubCommit />, rootElement);
