import React from 'react';
import AppHeading from '../../components/heading/app.rest.heading';
import './app.contactus.scss';

function AppContactus(props) {
    return (
        <React.Fragment>
          <AppHeading heading="CONTACT US"/>
          <section id="rest-conactus">
            <article>
              <div className="row">
                {props.ContactUs && props.ContactUs.slice(0,1).map((ContactUs, index) => (
                  <div className="map" key={index}>
                    <iframe frameBorder="0" draggable={true} style={{ width: "100%", height: "100%"}} src={ContactUs.MAP_URL+ContactUs.API_KEY}></iframe>
                  </div>
                ))}
                <div className="col">
                  {props.ContactUs && props.ContactUs.slice(1,4).map((ContactUs, index) => (
                    <div className="address" key={index}>
                      <h4 className="sub-heading">​{ContactUs.country}</h4>
                      <p>​{ContactUs.address}</p>
                      <p>​{ContactUs.phone}</p>
                      <p>​{ContactUs.email}</p>
                      </div>
                      ))}
                  </div>
                </div>
            </article>
          </section>
        </React.Fragment>
    )
}

export default AppContactus;
