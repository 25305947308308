import React from 'react';
import AppHeading from '../../components/heading/app.rest.heading';
import './app.services.scss';

function AppServices(props) {
    return (
      <React.Fragment>
        <AppHeading heading="OUR SERVICES"/>
        <section id="rest-services">
          <article>
          <p>Some text goes here</p>
          </article>
        </section>
      </React.Fragment>
    )
}

export default AppServices;
